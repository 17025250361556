import React from "react"
import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const PasswordField = React.forwardRef(({label, name, fullWidth, required, error, onChange, autoFocus, disabled, helperText, inputRef}, ref) => {
    const [passwordVisible, setPasswordVisible] = React.useState(false);

    return (
        <FormControl fullWidth={fullWidth} required={required} error={error}>
            <InputLabel required={required}>
                {label}
            </InputLabel>
            <OutlinedInput
                type={passwordVisible ? "text" : "password"}
                label={label}
                onChange={onChange}
                autoFocus={autoFocus}
                disabled={disabled}
                name={name}
                inputRef={inputRef}
                ref={ref}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end"
                            tabIndex={-1}
                        >
                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
        );
    }
);

export default PasswordField;