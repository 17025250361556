export default class Permission {
    static toDisplayText(name) {
        if (typeof name === "string") {
            name = Number.parseInt(name);
        }

        switch (name) {
            case 0:
                return "Administrator";
            case 1:
                return "User";
            case 2:
                return "No access";
            default:
                return "Invalid";
        }
    }
};