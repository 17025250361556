export class NotAuthorizedError extends Error {
    constructor(message = "Not authorized to access this resource.") {
        super(message);
        this.name = "NotAuthorizedError";
    }
}

export class AuthenticationRequiredError extends Error {
    constructor(message = "Authentication is required to access this resource.") {
        super(message);
        this.name = "AuthenticationRequiredError";
    }
}

export class NotFoundError extends Error {
    constructor(message = "The requested resource could not be found.") {
        super(message);
        this.name = "NotFoundError";
    }
}