import {
    Card, CardContent, CardHeader, CircularProgress,
    FormControl,
    InputLabel, List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {Person, Security} from "@mui/icons-material";
import React from "react";
import Permission from "../../models/Permission"
import {useFetcher} from "react-router-dom";

function MachinePermissionEntry({users, machine, machinePermission, canEdit}) {
    const useDropdownSelect = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [permissionSelection, setPermissionSelection] = React.useState(machinePermission.permission_level);
    const [loading, setLoading] = React.useState(false);
    const fetcher = useFetcher();
    const formRef = React.createRef(null);

    React.useEffect(() => {
        if (Array.isArray(fetcher.data) && fetcher.data.length === 0) {
            setTimeout(() => setLoading(false), 200);
        }
    }, [fetcher.data]);

    let user = users.filter(user => machinePermission.principal === user.id);

    if (user.length === 0) {
        return;
    }
    user = user[0];

    if (user.role === 2) {
        return;
    }

    const permissionChangeHandler = (event, value) => {
        setPermissionSelection(value);
        setLoading(true);
        Array.from(formRef.current).forEach(el => {
            if (el.tagName.toLowerCase() === "input" && el.name === "permissionLevel") {
                el.value = value;
            }
        });
        fetcher.submit(formRef.current, {
            replace: false,
        });
    }

    return (
        <fetcher.Form method="put" action={`/machines/${machine.id}/updatePermissions`} ref={formRef}>
            <input type="hidden" name="principal" value={user.id} />
            <input type="hidden" name="permissionLevel" value={permissionSelection} />
            <ListItem
                secondaryAction={
                <>
                    {
                        canEdit ? loading ? <CircularProgress size={24} /> : useDropdownSelect ?
                            <FormControl>
                                <InputLabel>Permission level</InputLabel>
                                <Select
                                    disabled={fetcher.state !== "idle"}
                                    size="small"
                                    value={machinePermission.permission_level}
                                    label="Permission level"
                                    onChange={event => permissionChangeHandler(event, event.target.value)}
                                    sx={{width: 140}}
                                >
                                    <MenuItem value={0}>Administrator</MenuItem>
                                    <MenuItem value={1}>User</MenuItem>
                                    <MenuItem value={2}>No access</MenuItem>
                                </Select>
                            </FormControl> : <ToggleButtonGroup
                                value={machinePermission.permission_level}
                                exclusive
                                disabled={fetcher.state !== "idle"}
                                color="primary"
                                onChange={(event, value) => {value !== null && permissionChangeHandler(event, value)}}
                                aria-label="text alignment"
                            >
                                <ToggleButton
                                    value={0}
                                    aria-label="Administrator"
                                    type="button"
                                    disabled={loading}
                                >
                                    Administrator
                                </ToggleButton>
                                <ToggleButton
                                    value={1}
                                    aria-label="Standard user"
                                    type="button"
                                    disabled={loading}
                                >
                                    User
                                </ToggleButton>
                                <ToggleButton
                                    value={2}
                                    aria-label="No access"
                                    type="button"
                                    disabled={loading}
                                >
                                    No access
                                </ToggleButton>
                            </ToggleButtonGroup>
                            : <Typography color="text.disabled">{Permission.toDisplayText(permissionSelection)}</Typography>
                    }
                </>
            }
            >
                <ListItemIcon>
                    <Person />
                </ListItemIcon>
                <ListItemText primary={user.displayName} secondary={user.getRoleChip("small")} />
            </ListItem>
        </fetcher.Form>
    )
}

function MachinePermissionsCard({
    machine,
    users,
    machinePermissions,
    canEdit = false,
}) {
    return (
        <Card variant="outlined">
            <CardHeader
                avatar={<Security/>}
                title="Access Permissions"
            />
            <CardContent sx={{p: 0}}>
                <List dense>
                    {machinePermissions.map(perm => (
                        <MachinePermissionEntry
                            machine={machine}
                            machinePermission={perm}
                            users={users}
                            canEdit={canEdit}
                            key={`machine-setperm-${perm.principal}`}
                        />
                    ))}
                </List>
            </CardContent>
            <span></span>
        </Card>
    )
}

export default MachinePermissionsCard;