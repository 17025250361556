import FullPageLayout from "../../components/layouts/FullPageLayout";
import {redirect, useLoaderData, useNavigate} from "react-router-dom";
import User from "../../models/User";
import React from "react";
import UserProfileCard from "../../components/utils/UserProfileCard";
import Permission from "../../models/Permission";
import UserPermissionsCard from "../../components/utils/UserPermissionsCard";
import Machine from "../../models/Machine";

export const loader = appContext => async ({ params }) => {
    const [
        currentUser,
        rawUserData,
        rawAllMachines,
        rawUserPermissions,
        machineTypes
    ] = await Promise.all([
        appContext.user.require(),
        appContext.axios.get(`users/${params.userId}`),
        appContext.axios.get(`machines`),
        appContext.axios.get(`users/${params.userId}/machines`),
        appContext.axios.get(`machines/types`)
    ]);

    const allMachines = rawAllMachines.data.machines.map(Machine.fromApiData).sort((a, b) => (a.hostName.toLowerCase() < b.hostName.toLowerCase() ? -1 : 1));
    const selectedUser = User.fromApiData(rawUserData.data);
    const selectedUserPermissions = allMachines.map((machine) => {
        const permissionEntries = rawUserPermissions.data.permissions.filter(perm => perm.machine_id === machine.id);
        if (permissionEntries.length === 0) {
            return {
                machine: machine.id,
                permission_level: 2,
            };
        } else {
            return {
                machine: permissionEntries[0].machine_id,
                permission_level: Number.parseInt(permissionEntries[0].permission_level)
            };
        }
    });


    return {
        currentUser: currentUser,
        allMachines: allMachines,
        machineTypes: machineTypes.data.machines,
        selectedUser: selectedUser,
        selectedUserPermissions: selectedUserPermissions,
    };
}

export const action = appContext => async ({ request, params }) => {
    let formData = await request.formData();
    let data = {};

    switch (params.action) {
        case "addKey":
            try {
                await appContext.axios.post(`/users/${params.userId}/keys`, {
                    publicKey: formData.get("publicKey")
                });
                appContext.enqueueSnackbar("Added public key.");
                appContext.user.fetch();
                return [];
            } catch (e) {
                appContext.enqueueSnackbar("Server rejected public key.");
                return ["The public key was rejected by the server. Please check that it is not used by another user."]
            }

        case "deleteKey":
            await appContext.axios.delete(`/users/${params.userId}/keys/${formData.get("key")}`);
            appContext.enqueueSnackbar("Removed public key.");
            appContext.user.fetch();
            break;

        case "updateProfile":
            data = {
                display_name: formData.get("displayName"),
                username: formData.get("userName"),
                role: formData.get("role"),
            }
            await appContext.axios.put(`/users/${params.userId}`, data);
            appContext.enqueueSnackbar("Saved changes to profile.");
            appContext.user.fetch();
            break;

        case "updatePassword":
            data = {
                password: formData.get("newPassword"),
                password2: formData.get("newPasswordRepeat")
            };
            if (formData.has("oldPassword")) {
                data["old_password"] = formData.get("oldPassword");
            }

            try {
                await appContext.axios.put(`/users/${params.userId}/password`, data);
                appContext.enqueueSnackbar("Changed password.");
                return [];
            } catch (e) {
                let messages = [];
                if (typeof e.response?.data !== "object") {
                    return ["The server sent an unexpected response."];
                }
                Object.keys(e.response.data).forEach(m => {
                    messages = [...messages, ...e.response.data[m]]
                });
                return messages;
            }

        case "updatePermissions":
            data = {
                machine: Number.parseInt(formData.get("machine")),
                permission_level: Number.parseInt(formData.get("permissionLevel"))
            };
            await appContext.axios.put(`/users/${params.userId}/machines`, data);
            appContext.enqueueSnackbar(`Updated permission to "${Permission.toDisplayText(data.permission_level)}".`);
            return [];

        case "disable":
            await appContext.axios.put(`/users/${params.userId}`, {
                role: 2
            });
            appContext.enqueueSnackbar("Disabled user.");
            return redirect("/users");

        case "delete":
            await appContext.axios.delete(`/users/${params.userId}`);
            appContext.enqueueSnackbar("Deleted user.");
            return redirect("/users");

        default:
            return null;
    }
    return null;
}

function UsersEntryPage(props) {
    const {currentUser, selectedUser, allMachines, selectedUserPermissions} = useLoaderData();
    const navigate = useNavigate();
    const canModifyUser = currentUser.isAdmin() || currentUser.id === selectedUser.id;

    return (
        <FullPageLayout
            title={canModifyUser ? "Manage User" : "View User"}
            description={`The following data is known for the user ${selectedUser.userName}.`}
            onBack={() => navigate("/users/")}
            user={currentUser}
        >
            <UserProfileCard
                user={selectedUser}
                showChangePassword={canModifyUser}
                showChangeProfile={canModifyUser}
                changePasswordRequiresOld={!currentUser.isAdmin()}
                canChangeSshKeys={canModifyUser}
                canChangeRole={currentUser.isAdmin() && currentUser.id !== selectedUser.id}
                canChangeDisplayName={canModifyUser}
            />

            <UserPermissionsCard
                machines={allMachines}
                user={selectedUser}
                userPermissions={selectedUserPermissions}
                canEdit={currentUser.isAdmin()}
            />
        </FullPageLayout>
    );
}

export default UsersEntryPage;