import Container from "@mui/material/Container";
import "./AuthBoxLayout.css"
import {
    Divider,
    Typography,
    Stack,
    IconButton,
    Tooltip
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React from "react";
import MainAppBar from "../utils/MainAppBar";

function FullPageLayout({title = null, description = null, user = null, children = <></>, onBack = null}) {
    return (
        <>
            <MainAppBar user={user} />
            <Container maxWidth="lg" sx={{mt: 2, pb: 2}}>
                <header>
                    <Stack direction="row" spacing={1} sx={{mb: 2}}>
                        {
                            onBack !== null && <Tooltip title="Back">
                                <IconButton onClick={onBack}>
                                    <ArrowBack />
                                </IconButton>
                            </Tooltip>
                        }
                        <Typography variant="h1">{title}</Typography>
                    </Stack>
                    <Typography variant="body1">{description}</Typography>
                    <Divider sx={{mt: 4, mb: 4}} />
                </header>
                {children}
            </Container>
        </>
    );
}

export default FullPageLayout;