import AuthBoxLayout from "../../components/layouts/AuthBoxLayout";
import {Alert, Backdrop, Box, Button, CircularProgress, Divider, TextField} from "@mui/material";
import PasswordField from "../../components/utils/PasswordField";
import {Navigate, useFetcher, useLoaderData, useNavigate} from "react-router-dom";
import React, {useState} from "react"
import {ArrowForward} from "@mui/icons-material";

export const loader = appContext => async ({props}) => {
    return {
        currentUser: await appContext.user.get()
    };
}

export const action = appContext => async ({request}) => {
    let formData = await request.formData();

    try {
        await appContext.user.login(
            formData.get("userName"),
            formData.get("password")
        );
        return [];
    } catch (e) {
        return [e.message];
    }
}

function LoginPage(props) {
    const navigate = useNavigate();
    const fetcher = useFetcher();
    const { currentUser } = useLoaderData();
    const [errors, setErrors] = useState([]);

    React.useEffect(() => {
        const actionData = fetcher.data;
        if (fetcher.state === "idle" && Array.isArray(actionData)) {
            if (actionData.length === 0) {
                navigate("/");
                return;
            }
            setErrors(actionData);
        }
    }, [fetcher.data, fetcher.state, navigate]);
    // Enforce that authenticated users cannot use login page
    if (currentUser !== null) {
        return <Navigate to={"/"} replace />;
    }

    const registerClickHandler = () => {
        navigate("/auth/register");
    }

    return <AuthBoxLayout
        title="Sign in"
        description="Please enter your credentials to continue to the lab management utility."
    >
        <fetcher.Form method="post" action="/auth/login">
            <TextField
                autoFocus
                fullWidth
                required
                label="Username"
                variant="outlined"
                name="userName"
                helperText="Usually one letter of your first name followed by your last name."
            />

            <PasswordField
                fullWidth
                required
                label="Password"
                variant="outlined"
                name="password"
                helperText="Can be reset by an administrator if lost."
            />

            {
                errors.map((message, index) => (
                    <Alert key={`login-err-${index}`} variant="outlined" severity="error" sx={{mt: 2}}>
                        { message }
                    </Alert>
                ))
            }

            <Divider variant="middle" sx={{mt: 4, mb: 4}} />

            <Box sx={{display: "flex"}}>
                <Button
                    sx={{mr: "auto"}}
                    variant="outlined"
                    type="button"
                    onClick={registerClickHandler}
                >
                    Register new account
                </Button>

                <Button
                    type="submit"
                    variant="contained"
                    startIcon={<ArrowForward />}
                >
                    Sign in
                </Button>
            </Box>

            <Backdrop open={fetcher.state !== "idle"} sx={{color: "#fff", zIndex: theme => theme.zIndex.drawer + 1}}>
                <CircularProgress size={64} color="inherit" />
            </Backdrop>
        </fetcher.Form>

    </AuthBoxLayout>
}

export default LoginPage;