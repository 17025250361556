import {
    Card,
    CardContent,
    CardHeader,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import {Badge, Dns, LocationOn, Sell} from "@mui/icons-material";
import React from "react";
import MachineEditDialog from "./MachineEditDialog";

function MachineOverviewCard({
    machine,
    machineTypes,
    canEdit = false,
}) {

    let machineTypeDisplay = "Unknown";
    machineTypes.forEach(([identifier, descriptor]) => {
        if (machine.machineType === identifier) {
            machineTypeDisplay = descriptor;
        }
    });

    return (
        <Card variant="outlined">
            <CardHeader
                avatar={<Dns />}
                title="Configuration"
                action={
                    canEdit &&
                    <MachineEditDialog machine={machine} machineTypes={machineTypes} />
                }>
            </CardHeader>
            <CardContent sx={{p: 0}}>
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <Badge />
                        </ListItemIcon>
                        <ListItemText primary={machine.displayName} secondary="Display name"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <LocationOn />
                        </ListItemIcon>
                        <ListItemText primary={machine.hostName} secondary="Host name"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Sell />
                        </ListItemIcon>
                        <ListItemText primary={machineTypeDisplay} secondary="Machine type"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Dns color={machine.online === true ? "success" : "error"} />
                        </ListItemIcon>
                        <ListItemText primary={machine.online ? "Recently seen online" : "Recently seen offline"} secondary="Connectivity status"/>
                    </ListItem>
                </List>
            </CardContent>
            <span></span>
        </Card>
    )
}

export default MachineOverviewCard;