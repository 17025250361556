import React from "react";
import {useFetcher, useLoaderData, useNavigate} from "react-router-dom";
import FullPageLayout from "../../components/layouts/FullPageLayout";
import {
    Box,
    Button, Checkbox,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip,
} from "@mui/material";
import {Circle, Delete, Edit, Visibility} from "@mui/icons-material";
import MachineAddDialog from "../../components/utils/MachineAddDialog";
import Machine from "../../models/Machine";

export const loader = appContext => async ({ params }) => {
    const currentUser = await appContext.user.require();
    let machines = (await appContext.axios.get("/machines")).data?.machines;
    let machineTypes = (await appContext.axios.get("/machines/types")).data?.machines;

    // Check api response for correct format
    if (!Array.isArray(machines)) {
        throw new Error("Received invalid response from server.");
    }

    // Convert api response to client user model and sort by username
    machines = machines.map(Machine.fromApiData).sort((a, b) => (a.hostName.toLowerCase() < b.hostName.toLowerCase() ? -1 : 1));

    return {
        currentUser: currentUser,
        machines: machines,
        machineTypes: machineTypes
    };
}

export const action = appContext => async ({ request, params }) => {
    let formData = await request.formData();

    const data = {
        display_name: formData.get("displayName"),
        machineType: formData.get("machineType"),
        hostName: formData.get("hostName"),
        configuration: formData.get("configuration"),
    };

    try {
        await appContext.axios.post(`/machines`, data);
        appContext.enqueueSnackbar("Added lab machine.");
        return [];
    } catch (e) {
        let messages = [];
        if (typeof e.response?.data !== "object") {
            return ["The server sent an unexpected response."];
        }
        Object.keys(e.response.data).forEach(m => {
            messages = [...messages, ...e.response.data[m]]
        });
        return messages;
    }
}

function MachinesItem({ machine, machineTypes, canEdit, canDelete }) {
    const navigate = useNavigate();
    const fetcher = useFetcher();

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [checkboxChecked, setCheckboxChecked] = React.useState(false);

    let machineTypeDisplay = "Unknown";
    machineTypes.forEach(([identifier, descriptor]) => {
        if (machine.machineType === identifier) {
            machineTypeDisplay = descriptor;
        }
    });

    return (
        <>
            <TableRow hover>
                <TableCell>
                    <Box sx={{display: "flex"}}>
                        <Tooltip title={machine.online === true ? "Machine was reported to be online." : "Machine was reported to be offline."}>
                            <Circle color={machine.online === true ? "success" : "error"} fontSize="small" />
                        </Tooltip>
                        <div style={{marginLeft: 4}}>{machine.displayName}</div>
                    </Box>
                </TableCell>
                <TableCell>{machine.hostName}</TableCell>
                <TableCell>{machineTypeDisplay}</TableCell>
                <TableCell align="right" sx={{py: 0}}>
                    <Tooltip title={canEdit ? "Edit" : "View"} placement="left">
                        <IconButton onClick={() => navigate(`/machines/${machine.id}`)}>
                            {(canEdit) ? <Edit /> : <Visibility />}
                        </IconButton>
                    </Tooltip>
                    {(canDelete) &&
                        <Tooltip title="Delete" placement="right">
                            <IconButton onClick={() => setDialogOpen(true)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    }
                </TableCell>
            </TableRow>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Delete machine</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove the machine <code>{machine.displayName}</code>?
                        You can also disable the user to prevent it from accessing the LUM and the lab machines.
                    </DialogContentText>
                    <FormControlLabel control={
                        <Checkbox
                            onChange={e => setCheckboxChecked(e.target.checked)}
                            checked={checkboxChecked}
                        />
                    } label="I am aware that the machine and permissions linked to it cannot be restored after deletion." />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
                    <fetcher.Form action={`/machines/${machine.id}/delete`} method="delete" onSubmit={() => setDialogOpen(false)}>
                        <Button type="submit" disabled={!checkboxChecked} color="error">Remove machine</Button>
                    </fetcher.Form>
                </DialogActions>
            </Dialog>
        </>
    );
}

function MachinesIndexPage(props) {
    const {currentUser, machines, machineTypes} = useLoaderData();
    const navigate = useNavigate();

    return <FullPageLayout
        title="Lab machines"
        description="The following lab machines are currently configured."
        user={currentUser}
        onBack={() => navigate("/")}
    >
        <TableContainer component={Paper} variant="outlined">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Display&nbsp;name</TableCell>
                        <TableCell>Hostname</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell align="right" sx={{py: 0}}>
                            {currentUser.isAdmin() &&
                            <MachineAddDialog machineTypes={machineTypes} />
                            }
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {machines.length === 0 && (
                        <TableRow>
                            <TableCell align="center" colSpan={4}>
                                No machines configured.
                            </TableCell>
                        </TableRow>
                    )}
                    {machines.map((machine) => (
                        <MachinesItem
                            key={`user-row-${machine.id}`}
                            machine={machine}
                            machineTypes={machineTypes}
                            canEdit={currentUser.isAdmin()}
                            canDelete={currentUser.isAdmin()}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </FullPageLayout>;
}

export default MachinesIndexPage;