import FullPageLayout from "../../components/layouts/FullPageLayout";
import {redirect, useLoaderData, useNavigate} from "react-router-dom";
import React from "react";
import MachineOverviewCard from "../../components/utils/MachineOverviewCard";
import Machine from "../../models/Machine";
import User from "../../models/User";
import Permission from "../../models/Permission";
import MachinePermissionsCard from "../../components/utils/MachinePermissionsCard";

export const loader = appContext => async ({ params }) => {

    const [
        currentUser,
        rawMachineData,
        rawAllUsers,
        rawMachinePermissions,
        machineTypes
    ] = await Promise.all([
        appContext.user.require(),
        appContext.axios.get(`machines/${params.machineId}`),
        appContext.axios.get(`users`),
        appContext.axios.get(`machines/${params.machineId}/users`),
        appContext.axios.get(`machines/types`),
    ]);

    const allUsers = rawAllUsers.data.map(User.fromApiData).sort((a, b) => (a.userName.toLowerCase() < b.userName.toLowerCase() ? -1 : 1));
    const selectedMachine = Machine.fromApiData(rawMachineData.data);
    const selectedMachinePermissions = allUsers.map((user) => {
        const permissionEntries = rawMachinePermissions.data.permissions.filter(perm => perm.principal_id === user.id);
        if (permissionEntries.length === 0) {
            return {
                principal: user.id,
                permission_level: 2,
            };
        } else {
            return {
                principal: permissionEntries[0].principal_id,
                permission_level: Number.parseInt(permissionEntries[0].permission_level)
            };
        }
    });

    return {
        currentUser: currentUser,
        allUsers: allUsers,
        machineTypes: machineTypes.data.machines,
        selectedMachine: selectedMachine,
        selectedMachinePermissions: selectedMachinePermissions,
    };
}

export const action = appContext => async ({ request, params }) => {
    let formData = await request.formData();
    let data = {};

    switch (params.action) {

        case "updateConfig":
            data = {
                hostName: formData.get("hostName"),
                display_name: formData.get("displayName"),
                machineType: formData.get("machineType"),
                configuration: formData.get("configuration"),
            }

            try {
                await appContext.axios.put(`/machines/${params.machineId}`, data);
                appContext.enqueueSnackbar("Saved changes to machine.");
                return [];
            } catch (e) {
                let messages = [];
                if (typeof e.response?.data !== "object") {
                    return ["The server sent an unexpected response."];
                }
                Object.keys(e.response.data).forEach(m => {
                    messages = [...messages, ...e.response.data[m]]
                });
                return messages;
            }

        case "updatePermissions":
            data = {
                principal: Number.parseInt(formData.get("principal")),
                permission_level: Number.parseInt(formData.get("permissionLevel"))
            };
            await appContext.axios.put(`/machines/${params.machineId}/users`, data);
            appContext.enqueueSnackbar(`Updated permission to "${Permission.toDisplayText(data.permission_level)}".`);
            return [];


        case "delete":
            await appContext.axios.delete(`/machines/${params.machineId}`);
            appContext.enqueueSnackbar("Deleted machine.");
            return redirect("/machines");

        default:
            return null;
    }
}

function MachinesEntryPage(props) {
    const {currentUser, selectedMachine, selectedMachinePermissions, machineTypes, allUsers} = useLoaderData();
    const navigate = useNavigate();
    const canEditMachine = currentUser.isAdmin();

    return (
        <FullPageLayout
            title={canEditMachine ? "Manage Machine" : "View Machine"}
            description={`The following data is known for the machine ${selectedMachine.displayName}.`}
            onBack={() => navigate("/machines/")}
            user={currentUser}
        >
            <MachineOverviewCard
                machine={selectedMachine}
                machineTypes={machineTypes}
                canEdit={canEditMachine}
            />

            <MachinePermissionsCard
                machine={selectedMachine}
                users={allUsers}
                machinePermissions={selectedMachinePermissions}
                canEdit={canEditMachine}
            />
        </FullPageLayout>
    );
}

export default MachinesEntryPage;