export default class Machine {
    constructor() {
        this.id = 0;
        this.machineType = "";
        this.hostName = "";
        this.displayName = "";
        this.configuration = "{}";
        this.online = false;
    }
    // Build User object from API request data
    static fromApiData(data) {
        // Check sanity of received data
        if (typeof data.id !== "number" ||
            typeof data.machineType !== "string" ||
            typeof data.hostName !== "string" ||
            typeof data.display_name !== "string" ||
            typeof data.configuration !== "string" ||
            typeof data.online !== "boolean") {
            return null;
        }

        const machine = new Machine();
        machine.id = data.id;
        machine.machineType = data.machineType;
        machine.hostName = data.hostName;
        machine.displayName = data.display_name;
        machine.configuration = data.configuration;
        machine.online = data.online;
        return machine;
    }
}