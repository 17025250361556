import {Chip} from "@mui/material";

export default class User {
    constructor() {
        this.id = 0;
        this.userName = 0;
        this.displayName = "";
        this.sshKeys = [];
        this.role = 0;
    }

    isAdmin() {
        return this.role === 0;
    }

    isStandardUser() {
        return this.role === 1;
    }

    isDisabled() {
        return this.role === 2;
    }

    // Get the description text for a user's role
    getRoleText() {
        switch (this.role) {
            case 0:
                return "Administrator";
            case 1:
                return "Standard User";
            case 2:
                return "Disabled User";
            default:
                return "Invalid User";
        }
    }

    // Get an appropriately colored chip representing a user's role
    getRoleChip(size = "small") {
        switch (this.role) {
            case 0:
                return <Chip label="Administrator" variant="outlined" color="error" size={size} component="span" />;
            case 1:
                return <Chip label="Standard User" variant="outlined" color="primary" size={size} component="span" />;
            case 2:
                return <Chip label="Disabled User" variant="outlined" size={size} component="span" />;
            default:
                return <Chip label="Invalid User" variant="outlined" color="error" size={size} component="span" />;
        }
    }

    equals(other) {
        if (other === null || other === undefined) {
            return false;
        }

        if (other.id !== this.id || other.userName !== this.userName || other.displayName !== this.displayName
            || other.role !== this.role || other.sshKeys.length !== this.sshKeys.length) {
            return false;
        }

        // Check if SSH keys have changed
        for (let i = 0; i < this.sshKeys.length; i++) {
            if (other.sshKeys.filter(key => this.sshKeys[i].publicKey === key.publicKey).length === 0) {
                return false;
            }
        }
        return true;
    }

    // Build User object from API request data
    static fromApiData(data) {
        // Check sanity of received data
        if (typeof data.id !== "number" || typeof data.username !== "string" || typeof data.display_name !== "string" || typeof data.role !== "number" || typeof data.ssh_keys !== "object") {
            return null;
        }

        const user = new User();
        user.id = data.id;
        user.userName = data.username;
        user.displayName = data.display_name;
        user.role = data.role;
        user.sshKeys = data.ssh_keys;
        return user;
    }

}