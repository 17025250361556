import FullPageLayout from "../components/layouts/FullPageLayout";
import {useLoaderData, useNavigate} from "react-router-dom";
import React from "react";
import UserProfileCard from "../components/utils/UserProfileCard";
import Machine from "../models/Machine";
import UserPermissionsCard from "../components/utils/UserPermissionsCard";
import {Card, CardActionArea, CardContent, Grid, Stack, Typography} from "@mui/material";
import User from "../models/User";
import {ArrowForward} from "@mui/icons-material";

export const loader = appContext => async ({ params }) => {
    const currentUser = await appContext.user.require();

    const [
        rawAllMachines,
        rawAllUsers,
        rawUserPermissions
    ] = await Promise.all([
        appContext.axios.get(`machines`),
        appContext.axios.get(`users`),
        appContext.axios.get(`users/${currentUser.id}/machines`)
    ]);

    const allMachines = rawAllMachines.data.machines.map(Machine.fromApiData).sort((a, b) => (a.hostName.toLowerCase() < b.hostName.toLowerCase() ? -1 : 1));
    const allUsers = rawAllUsers.data.map(User.fromApiData).sort((a, b) => (a.userName.toLowerCase() < b.userName.toLowerCase() ? -1 : 1));
    const selectedUserPermissions = allMachines.map((machine) => {
        const permissionEntries = rawUserPermissions.data.permissions.filter(perm => perm.machine_id === machine.id);
        if (permissionEntries.length === 0) {
            return {
                machine: machine.id,
                permission_level: 2,
            };
        } else {
            return {
                machine: permissionEntries[0].machine_id,
                permission_level: Number.parseInt(permissionEntries[0].permission_level)
            };
        }
    });

    return {
        currentUser: currentUser,
        allMachines: allMachines,
        allUsers: allUsers,
        currentUserPermissions: selectedUserPermissions,
    };
}


export default function HomePage(props) {
    const {currentUser, allMachines, allUsers, currentUserPermissions} = useLoaderData();
    const navigate = useNavigate();

    return (
        <FullPageLayout
            title="Overview"
            description="Welcome to the RootSec Lab User Management!"
            user={currentUser}
        >
            <Typography variant="h2" sx={{mb: 2}}>
                Main actions
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardActionArea onClick={() => navigate(`/users/${currentUser.id}`)}>
                            <CardContent>
                                <Stack direction="horizontal">
                                    <Typography gutterBottom variant="h2" component="div" sx={{mr: 1}}>
                                        My profile
                                    </Typography>
                                    <ArrowForward />
                                </Stack>
                                <Typography gutterBottom variant="body1" component="div">
                                    Currently, {currentUser.sshKeys.length === 0 ? "no public keys are" : currentUser.sshKeys.length === 1 ? "one public key is" : currentUser.sshKeys.length + " public keys are"} enrolled.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardActionArea onClick={() => navigate("/users")}>
                            <CardContent>
                                <Stack direction="horizontal">
                                    <Typography gutterBottom variant="h2" component="div" sx={{mr: 1}}>
                                        {currentUser.isAdmin() ? "Manage" : "View"} users
                                    </Typography>
                                    <ArrowForward />
                                </Stack>
                                <Typography gutterBottom variant="body1" component="div">
                                    Currently, {allUsers.length === 1 ? "one user is" : allUsers.length + " users are"} registered.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardActionArea onClick={() => navigate("/machines")}>
                            <CardContent>
                                <Stack direction="horizontal">
                                    <Typography gutterBottom variant="h2" component="div" sx={{mr: 1}}>
                                        {currentUser.isAdmin() ? "Manage" : "View"} machines
                                    </Typography>
                                    <ArrowForward />
                                </Stack>
                                <Typography gutterBottom variant="body1" component="div">
                                    Currently, {allMachines.length === 0 ? "no machines are" : allMachines.length === 1 ? "one machine is" : allMachines.length + " machines are"} configured.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardActionArea onClick={() => navigate("/docs")}>
                            <CardContent>
                                <Stack direction="horizontal">
                                    <Typography gutterBottom variant="h2" component="div" sx={{mr: 1}}>
                                        View API docs
                                    </Typography>
                                    <ArrowForward />
                                </Stack>
                                <Typography gutterBottom variant="body1" component="div">
                                    List of available API endpoints
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            <Typography variant="h2" gutterBottom sx={{mb: 2, mt: 2}}>
                Account information
            </Typography>
            <UserProfileCard
                user={currentUser}
                hideHeader
            />
            <Typography variant="h2" sx={{mb: 2, mt: 4}}>
                Available lab machines
            </Typography>
            <UserPermissionsCard
                user={currentUser}
                machines={allMachines}
                userPermissions={currentUserPermissions}
                hideHeader
            />
        </FullPageLayout>
    )
}