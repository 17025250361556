import React from "react";
import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select,
    TextField, Toolbar, Typography, useMediaQuery, useTheme
} from "@mui/material";
import {Edit, Close} from "@mui/icons-material";
import {useFetcher} from "react-router-dom";
import SlideUpTransition from "./SlideUpTransition";

function UserProfileEditDialog({ user, canChangeDisplayName = false, canChangeRole = false }) {
    const fetcher = useFetcher();
    const useFullScreenDialog = useMediaQuery(useTheme().breakpoints.down('md'));

    const [dialogState, setDialogState] = React.useState({
        open: false,
        loading: false,
    });

    const submitHandler = e => {
        closeHandler(e);
    }

    const closeHandler = e => {
        setDialogState({
            ...dialogState,
            open: false,
        });
    }

    const openHandler = e => {
        setDialogState({
            ...dialogState,
            open: true,
        });
    }

    return <div>

        { (canChangeDisplayName || canChangeRole) &&
            <Button startIcon={<Edit />} type="button" onClick={openHandler}>
                Edit profile
            </Button>
        }

        <Dialog
            open={dialogState.open}
            onClose={closeHandler}
            maxWidth="md"
            fullWidth
            fullScreen={useFullScreenDialog}
            TransitionComponent={useFullScreenDialog ? SlideUpTransition : undefined}
        >
            <fetcher.Form method="put" action={`/users/${user.id}/updateProfile`} onSubmit={submitHandler}>
                { useFullScreenDialog &&
                    <>
                        <AppBar>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={closeHandler}
                                    aria-label="close"
                                >
                                    <Close />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h3" component="div">
                                    Edit profile
                                </Typography>
                                <Button autoFocus color="inherit" type="submit">
                                    Save
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <Toolbar />
                    </>
                }
                { useFullScreenDialog ||
                <DialogTitle>Edit profile of {user.displayName}</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText>
                        Please fill in the following fields to change the profile.
                    </DialogContentText>
                    <input type="hidden" name="userName" value={user.userName} />

                    {canChangeDisplayName ?
                        <TextField
                            autoFocus
                            fullWidth
                            required
                            defaultValue={user.displayName}
                            label="Display name"
                            name="displayName"
                            helperText="The full name (only used in the web interface)."
                        /> :
                        <input type="hidden" name="displayName" value={user.displayName} />
                    }

                    {canChangeRole ?
                        <FormControl fullWidth>
                            <InputLabel id="role-select-label">Role</InputLabel>
                            <Select
                                labelId="role-select-label"
                                defaultValue={user.role}
                                label="Role"
                                name="role"
                                autoFocus={!canChangeDisplayName}
                            >
                                <MenuItem value={0}>
                                    Administrator
                                </MenuItem>
                                <MenuItem value={1}>
                                    Standard User
                                </MenuItem>
                                <MenuItem value={2}>
                                    Disabled User
                                </MenuItem>
                            </Select>
                            <FormHelperText>
                                The role within the RootSec LUM.
                            </FormHelperText>
                        </FormControl> :
                        <input type="hidden" name="role" value={user.role} />
                    }
                </DialogContent>
                { useFullScreenDialog ||
                <DialogActions>
                    <Button onClick={closeHandler} type="button">Cancel</Button>
                    <Button variant="contained" type="submit">Change profile</Button>
                </DialogActions>
                }
            </fetcher.Form>
        </Dialog>
    </div>
}

export default UserProfileEditDialog;