import Container from "@mui/material/Container";
import "./AuthBoxLayout.css"
import {Paper, Typography} from "@mui/material";
import React from "react";
import MainAppBar from "../utils/MainAppBar";

function AuthBoxLayout(props) {
    return (
        <>
            <MainAppBar user={null} />
            <Container maxWidth="md" sx={{py: 4}}>
                <Paper variant="outlined" sx={{p: 4}}>
                    <header>
                        <Typography variant="h1" gutterBottom>{props.title}</Typography>
                        <Typography variant="body1">{props.description}</Typography>
                    </header>

                    <section>
                        {props.children}
                    </section>
                </Paper>
            </Container>
        </>
    );
}

export default AuthBoxLayout;