import React from "react";
import {List, ListItemButton, ListItemIcon, ListItemText, Radio} from "@mui/material";

function MachineTypeSelector({machineTypes, name, value = null}) {
    const [selected, setSelected] = React.useState(value === null ? machineTypes[0][0] : value);

    return <>
        <input type="hidden" name={name} value={selected} />
        <List>
            {
                machineTypes.map(([identifier, displayText]) => {
                    return (
                        <ListItemButton
                            key={identifier}
                            onClick={() => setSelected(identifier)}
                            dense
                        >
                            <ListItemIcon>
                                <Radio
                                    checked={selected === identifier}
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary={`${displayText}`} />
                        </ListItemButton>
                    );
                })
            }
        </List>
    </>
}

export default MachineTypeSelector;