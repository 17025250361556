import React from "react";
import {
    Alert, AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton, Toolbar, Typography,
    useMediaQuery, useTheme
} from "@mui/material";
import PasswordField from "./PasswordField";
import {Close, Password} from "@mui/icons-material";
import {useFetcher} from "react-router-dom";
import SlideUpTransition from "./SlideUpTransition";

function PasswordChangeDialog({ user, requireOldPassword }) {
    const fetcher = useFetcher();
    const useFullScreenDialog = useMediaQuery(useTheme().breakpoints.down('md'));

    const [messages, setMessages] = React.useState({
        oldPassword: null,
        newPassword: null,
        newPasswordRepeat: null,
    });

    const refs = {
        oldPassword: React.useRef(null),
        newPassword: React.useRef(null),
        newPasswordRepeat: React.useRef(null),
    }

    const [dialogState, setDialogState] = React.useState({
        open: false,
        loading: false,
        errors: [],
    });

    React.useEffect(() => {
        const actionData = fetcher.data;
        if (Array.isArray(actionData)) {
            setDialogState(dialogState => {
                if (!dialogState.loading) {
                    return dialogState;
                }

                return {
                    ...dialogState,
                    open: actionData.length !== 0,
                    loading: false,
                    errors: actionData
                };
            });
        }
    }, [fetcher.data]);


    const submitHandler = e => {
        let success = true;
        let newMessages = {};

        if (refs.newPassword.current.value.length < 8) {
            newMessages.newPassword = "The password has to be at least 8 characters long.";
            success = false;
        }

        if (refs.newPassword.current.value !== refs.newPasswordRepeat.current.value) {
            newMessages.newPasswordRepeat = "The password and the repetition don't match.";
            success = false;
        }

        if (!success) {
            e.preventDefault();
            setMessages({
                ...messages,
                ...newMessages
            });
            return;
        }

        setDialogState({
            ...dialogState,
            loading: true
        });
    }

    const fieldChangeHandler = e => {
        if (messages[e.target.name] !== null) {
            setMessages({
                ...messages,
                [e.target.name]: null
            });
        }
    }

    const closeHandler = () => {
        setDialogState({
            ...dialogState,
            open: false,
        });
    }

    const openHandler = () => {
        setDialogState({
            ...dialogState,
            open: true,
        });
    }

    return <div>
        <Button startIcon={<Password />} type="button" onClick={openHandler}>
            Change password
        </Button>

        <Dialog
            open={dialogState.open}
            onClose={closeHandler}
            maxWidth="md"
            fullWidth
            fullScreen={useFullScreenDialog}
            TransitionComponent={useFullScreenDialog ? SlideUpTransition : undefined}
        >
            <fetcher.Form method="put" action={`/users/${user.id}/updatePassword`} onSubmit={submitHandler} onChange={fieldChangeHandler}>
                { useFullScreenDialog &&
                    <>
                    <AppBar>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={closeHandler}
                                aria-label="close"
                            >
                                <Close />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h3" component="div">
                                Change password
                            </Typography>
                            <Button autoFocus color="inherit" type="submit">
                                Save
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Toolbar />
                    </>
                }
                { useFullScreenDialog ||
                    <DialogTitle>Change password of {user.displayName}</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText>
                        Please fill in the following fields to change the password.
                    </DialogContentText>

                    {requireOldPassword &&
                        <PasswordField
                            autoFocus
                            error={messages.oldPassword !== null}
                            name="oldPassword"
                            label="Current password"
                            helperText={messages.oldPassword}
                            inputRef={refs.oldPassword}
                            required
                            disabled={dialogState.loading}
                            fullWidth
                        />
                    }

                    <PasswordField
                        autoFocus={!requireOldPassword}
                        error={messages.newPassword !== null}
                        label="New password"
                        name="newPassword"
                        inputRef={refs.newPassword}
                        helperText={messages.newPassword !== null ? messages.newPassword : "This password is only used in the web interface but not for the lab machines."}
                        required
                        disabled={dialogState.loading}
                        fullWidth
                    />
                    <PasswordField
                        error={messages.newPasswordRepeat !== null}
                        label="Repeat new password"
                        name="newPasswordRepeat"
                        inputRef={refs.newPasswordRepeat}
                        helperText={messages.newPasswordRepeat !== null ? messages.newPasswordRepeat : "Repeat the password to prevent typos."}
                        required
                        disabled={dialogState.loading}
                        fullWidth
                    />
                    {
                        dialogState.errors.map((message, index) => (
                            <Alert key={`alert-chpw-${index}`} variant="outlined" severity="error" sx={{mt: 2}}>
                                {message}
                            </Alert>
                        ))
                    }
                </DialogContent>
                { useFullScreenDialog ||
                <DialogActions>
                    <Button onClick={closeHandler} type="button" disabled={dialogState.loading}>Cancel</Button>
                    <Button variant="contained" type="submit" disabled={dialogState.loading}>Change password</Button>
                </DialogActions>
                }
            </fetcher.Form>
        </Dialog>
    </div>
}

export default PasswordChangeDialog;