import {useFetcher} from "react-router-dom";
import {
    Alert,
    AppBar,
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Divider, FormControl, FormHelperText,
    IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React from "react";
import {Add, Close, RemoveCircleOutline} from "@mui/icons-material";
import SlideUpTransition from "./SlideUpTransition";
import PasswordField from "./PasswordField";

function UserAddDialog() {
    const fetcher = useFetcher();
    const useFullScreenDialog = useMediaQuery(useTheme().breakpoints.down('md'));

    const [dialogState, setDialogState] = React.useState({
        open: false,
        loading: false,
        errors: [],
    });

    React.useEffect(() => {
        const actionData = fetcher.data;
        if (Array.isArray(actionData)) {
            setDialogState(dialogState => {
                if (!dialogState.loading) {
                    return dialogState;
                }

                return {
                    ...dialogState,
                    open: actionData.length !== 0,
                    loading: false,
                    errors: actionData
                };
            });
        }
    }, [fetcher.data]);

    const [messages, setMessages] = React.useState({
        displayName: null,
        userName: null,
        password: null,
        passwordRepeat: null,
    });

    const [keys, setKeys] = React.useState({
        index: 0,
        keys: []
    });

    const refs = {
        displayName: React.useRef(null),
        userName: React.useRef(null),
        password: React.useRef(null),
        passwordRepeat: React.useRef(null),
    }

    const fieldChangeHandler = e => {
        if (messages[e.target.name] !== null) {
            setMessages({
                ...messages,
                [e.target.name]: null
            });
        }
    }

    const closeHandler = e => {
        setDialogState({
            ...dialogState,
            open: false,
            errors: [],
        });
    }

    const openHandler = e => {
        setDialogState({
            ...dialogState,
            open: true,
            errors: [],
        });
    }

    const keyAddHandler = () => {
        setKeys({
            index: keys.index + 1,
            keys: [...keys.keys, keys.index + 1]
        });
    }

    const keyRemoveHandler = index => () => {
        setKeys({
            ...keys,
            keys: keys.keys.filter(k => (k !== index))
        });
    }

    const submitHandler = e => {
        let success = true;
        let newMessages = {};

        if (refs.userName.current.value.match(/[^0-9a-z.\-_]/i)) {
            newMessages.userName = "Usernames may only consist of alphanumerical characters and dots, dashes and underscores.";
            success = false;
        }

        if (refs.password.current.value.length < 8) {
            newMessages.password = "The password has to be at least 8 characters long.";
            success = false;
        }

        if (refs.password.current.value !== refs.passwordRepeat.current.value) {
            newMessages.passwordRepeat = "The password and the repetition don't match.";
            success = false;
        }

        if (!success) {
            e.preventDefault();
            setMessages({
                ...messages,
                ...newMessages
            });
            return;
        }

        setDialogState({
            ...dialogState,
            loading: true
        });
    }

    return <>
        <Button startIcon={<Add />} type="button" onClick={openHandler}>
            Create&nbsp;user
        </Button>

        <Dialog
            open={dialogState.open}
            onClose={closeHandler}
            maxWidth="md"
            scroll="body"
            fullWidth
            fullScreen={useFullScreenDialog}
            TransitionComponent={useFullScreenDialog ? SlideUpTransition : undefined}
        >
            <fetcher.Form method="post" action={`/users/`} onSubmit={submitHandler}>
                { useFullScreenDialog &&
                    <>
                        <AppBar>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={closeHandler}
                                    aria-label="close"
                                >
                                    <Close />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h3" component="div">
                                    Create user
                                </Typography>
                                <Button autoFocus color="inherit" type="submit">
                                    Create
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <Toolbar />
                    </>
                }
                { useFullScreenDialog ||
                    <DialogTitle>Create new user</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText>
                        Please fill in the following fields to create a new user.
                    </DialogContentText>

                    <Divider variant="middle" sx={{mt: 4, mb: 4}} />

                    <Typography variant="h3" gutterBottom>
                        User information
                    </Typography>

                    <TextField
                        fullWidth
                        required
                        error={messages.displayName !== null}
                        label="Display name"
                        variant="outlined"
                        name="displayName"
                        inputRef={refs.displayName}
                        onChange={fieldChangeHandler}
                        helperText={messages.displayName !== null ? messages.displayName : "The full name (only used in the web interface)."}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="role-select-label">Role</InputLabel>
                        <Select
                            labelId="role-select-label"
                            label="Role"
                            name="role"
                            defaultValue={1}
                        >
                            <MenuItem value={0}>
                                Administrator
                            </MenuItem>
                            <MenuItem value={1}>
                                Standard User
                            </MenuItem>
                            <MenuItem value={2}>
                                Disabled User
                            </MenuItem>
                        </Select>
                        <FormHelperText>
                            The role within the RootSec LUM.
                        </FormHelperText>
                    </FormControl>
                    <TextField
                        fullWidth
                        required
                        error={messages.userName !== null}
                        label="Username"
                        variant="outlined"
                        name="userName"
                        inputRef={refs.userName}
                        onChange={fieldChangeHandler}
                        helperText={messages.userName !== null ? messages.userName : "Usually one letter of the first name followed by the last name."}
                    />
                    <PasswordField
                        fullWidth
                        required
                        error={messages.password !== null}
                        label="Password"
                        name="password"
                        inputRef={refs.password}
                        onChange={fieldChangeHandler}
                        helperText={messages.password !== null ? messages.password : "The password is only used for the web interface but not for the lab machines."}
                    />
                    <PasswordField
                        fullWidth
                        required
                        error={messages.passwordRepeat !== null}
                        label="Repeat Password"
                        name="passwordRepeat"
                        inputRef={refs.passwordRepeat}
                        onChange={fieldChangeHandler}
                        helperText={messages.passwordRepeat !== null ? messages.passwordRepeat : "Repeat the password to prevent typos."}
                    />

                    <Divider variant="middle" sx={{mt: 4, mb: 4}} />

                    <Stack direction="row">
                        <Typography variant="h3" gutterBottom>
                            SSH keys
                        </Typography>
                        <Button
                            type="button"
                            size="small"
                            sx={{ml: "auto", mt: "-4pt"}}
                            startIcon={<Add />}
                            onClick={keyAddHandler}
                        >
                            Add public key
                        </Button>
                    </Stack>

                    { !keys.keys.length &&
                        <Typography variant="body1" color="text.secondary" sx={{mt: 4}}><i>You haven't added any public keys yet.</i></Typography>
                    }

                    {
                        keys.keys.map((key) => {
                            return (
                                <FormControl key={`useradd-key-${key}`} sx={{ my: 2 }} variant="outlined" fullWidth>
                                    <InputLabel
                                        htmlFor={`useradd-key-${key}-inp`}
                                        required
                                    >Public SSH key</InputLabel>
                                    <OutlinedInput
                                        type="text"
                                        name="publicKey[]"
                                        id={`useradd-key-${key}-inp`}
                                        required
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="remove public key"
                                                    onClick={keyRemoveHandler(key)}
                                                    onMouseDown={e => e.preventDefault()}
                                                    edge="end"
                                                    color="error"
                                                >
                                                    <RemoveCircleOutline />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Public SSH key"
                                    />
                                    <FormHelperText>
                                        Public keys usually begin with <code>ssh-...</code>
                                    </FormHelperText>
                                </FormControl>
                            );
                        })
                    }
                    {
                        dialogState.errors.map((message, index) => (
                            <Alert key={`alert-useradd-${index}`} variant="outlined" severity="error" sx={{mt: 2}}>
                                {message}
                            </Alert>
                        ))
                    }

                </DialogContent>
                { useFullScreenDialog ||
                    <DialogActions>
                        <Button onClick={closeHandler}>Cancel</Button>
                        <Button type="submit" variant="contained">Create user</Button>
                    </DialogActions>
                }
            </fetcher.Form>
        </Dialog>
    </>
}

export default UserAddDialog;