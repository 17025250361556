import AuthBoxLayout from "../../components/layouts/AuthBoxLayout";
import {Add, ArrowForward, BadgeOutlined, RemoveCircleOutline } from "@mui/icons-material";
import {
    Alert,
    Backdrop,
    Box,
    Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider,
    FormControl, FormHelperText, IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput, Stack,
    TextField,
    Typography
} from "@mui/material";
import PasswordField from "../../components/utils/PasswordField";
import {Navigate, useFetcher, useLoaderData, useNavigate} from "react-router-dom";
import React, {useState} from "react"

export const loader = appContext => async () => {
    return {
        currentUser: await appContext.user.get()
    };
}

export const action = appContext => async ({request}) => {
    let formData = await request.formData();

    let sshKeys = [];
    formData.getAll("publicKey[]").forEach(k => {
        if (!(k in sshKeys)) {
            sshKeys = [...sshKeys, k]
        }
    });

    const data = {
        username: formData.get("userName"),
        display_name: formData.get("displayName"),
        password: formData.get("password"),
        password2: formData.get("passwordRepeat"),
        role: formData.get("role"),
        ssh_keys: sshKeys.map(k => ({
            publicKey: k
        })),
    };

    try {
        await appContext.axios.post(`/auth/register`, data);
        appContext.enqueueSnackbar("Registered new user.");
        return [];
    } catch (e) {
        let messages = [];
        if (typeof e.response?.data !== "object") {
            return ["The server sent an unexpected response."];
        }
        Object.keys(e.response.data).forEach(m => {
            if (m === "ssh_keys") {
                messages = [...messages, "At least one public key was rejected by the server. Please check that these are not used by another user."]
            } else if (Array.isArray(e.response.data[m])) {
                messages = [...messages, ...e.response.data[m]]
            }
        });
        return messages;
    }
}


function RegisterPage(props) {
    const { currentUser } = useLoaderData();
    const navigate = useNavigate();
    const fetcher = useFetcher();

    const [successDialogVisible, setSuccessDialogVisible] = useState(false);
    const [errors, setErrors] = useState([]);

    const [messages, setMessages] = React.useState({
        displayName: null,
        userName: null,
        password: null,
        passwordRepeat: null,
    });

    const [keys, setKeys] = React.useState({
        index: 0,
        keys: []
    });

    const refs = {
        displayName: React.useRef(null),
        userName: React.useRef(null),
        password: React.useRef(null),
        passwordRepeat: React.useRef(null),
    }

    React.useEffect(() => {
        const actionData = fetcher.data;
        if (fetcher.state === "idle" && Array.isArray(actionData)) {
            if (actionData.length === 0) {
                setSuccessDialogVisible(true);
            }
            setErrors(actionData);
        }
    }, [fetcher.data, fetcher.state]);

    // Enforce that authenticated users cannot use register page
    if (currentUser !== null) {
        return <Navigate to={"/"} replace />;
    }

    const fieldChangeHandler = e => {
        if (messages[e.target.name] !== null) {
            setMessages({
                ...messages,
                [e.target.name]: null
            });
        }
    }

    const keyAddHandler = () => {
        setKeys({
            index: keys.index + 1,
            keys: [...keys.keys, keys.index + 1]
        });
    }

    const keyRemoveHandler = index => () => {
        setKeys({
            ...keys,
            keys: keys.keys.filter(k => (k !== index))
        });
    }

    const submitHandler = e => {
        let success = true;
        let newMessages = {};

        if (refs.userName.current.value.match(/[^0-9a-z.\-_]/i)) {
            newMessages.userName = "Usernames may only consist of alphanumerical characters and dots, dashes and underscores.";
            success = false;
        }

        if (refs.password.current.value.length < 8) {
            newMessages.password = "The password has to be at least 8 characters long.";
            success = false;
        }

        if (refs.password.current.value !== refs.passwordRepeat.current.value) {
            newMessages.passwordRepeat = "The password and the repetition don't match.";
            success = false;
        }

        if (!success) {
            e.preventDefault();
            setMessages({
                ...messages,
                ...newMessages
            });
            return;
        }
    }

    const loginClickHandler = () => {
        navigate("/auth/login");
    }

    return <AuthBoxLayout
        icon={BadgeOutlined}
        title="Register account"
        description="Please fill in the following fields to register in the lab management utility."
    >
        <fetcher.Form method="post" action="/auth/register" onSubmit={submitHandler}>

            <Divider variant="middle" sx={{mt: 4, mb: 4}} />

            <Typography variant="h3" gutterBottom>
                User information
            </Typography>

            <TextField
                fullWidth
                required
                error={messages.displayName !== null}
                label="Display name"
                variant="outlined"
                name="displayName"
                inputRef={refs.displayName}
                onChange={fieldChangeHandler}
                helperText={messages.displayName !== null ? messages.displayName : "The full name (only used in the web interface)."}
            />
            <TextField
                fullWidth
                required
                error={messages.userName !== null}
                label="Username"
                variant="outlined"
                name="userName"
                inputRef={refs.userName}
                onChange={fieldChangeHandler}
                helperText={messages.userName !== null ? messages.userName : "Usually one letter of the first name followed by the last name."}
            />
            <PasswordField
                fullWidth
                required
                error={messages.password !== null}
                label="Password"
                name="password"
                inputRef={refs.password}
                onChange={fieldChangeHandler}
                helperText={messages.password !== null ? messages.password : "The password is only used for the web interface but not for the lab machines."}
            />
            <PasswordField
                fullWidth
                required
                error={messages.passwordRepeat !== null}
                label="Repeat Password"
                name="passwordRepeat"
                inputRef={refs.passwordRepeat}
                onChange={fieldChangeHandler}
                helperText={messages.passwordRepeat !== null ? messages.passwordRepeat : "Repeat the password to prevent typos."}
            />

            <Divider variant="middle" sx={{mt: 4, mb: 4}} />

            <Stack direction="row">
                <Typography variant="h3" gutterBottom>
                    SSH keys
                </Typography>
                <Button
                    type="button"
                    size="small"
                    sx={{ml: "auto", mt: "-4pt"}}
                    startIcon={<Add />}
                    onClick={keyAddHandler}
                >
                    Add public key
                </Button>
            </Stack>

            { !keys.keys.length &&
                <Typography variant="body1" color="text.secondary" sx={{mt: 4}}><i>You haven't added any public keys yet.</i></Typography>
            }

            {
                keys.keys.map((key) => {
                    return (
                        <FormControl key={`useradd-key-${key}`} sx={{ my: 2 }} variant="outlined" fullWidth>
                            <InputLabel
                                htmlFor={`useradd-key-${key}-inp`}
                                required
                            >Public SSH key</InputLabel>
                            <OutlinedInput
                                type="text"
                                name="publicKey[]"
                                id={`useradd-key-${key}-inp`}
                                required
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="remove public key"
                                            onClick={keyRemoveHandler(key)}
                                            onMouseDown={e => e.preventDefault()}
                                            edge="end"
                                            color="error"
                                        >
                                            <RemoveCircleOutline />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Public SSH key"
                            />
                            <FormHelperText>
                                Public keys usually begin with <code>ssh-...</code>
                            </FormHelperText>
                        </FormControl>
                    );
                })
            }

            <Divider variant="middle" sx={{mt: 4, mb: 4}} />

            {
                errors.map((message, index) => (
                    <Alert key={`alert-register-${index}`} variant="outlined" severity="error" sx={{mb: 2}}>
                        {message}
                    </Alert>
                ))
            }


            <Box sx={{display: "flex"}}>
                <Button
                    sx={{mr: "auto"}}
                    type="button"
                    variant="outlined"
                    onClick={loginClickHandler}
                >
                    Use existing account
                </Button>

                <Button
                    type="submit"
                    variant="contained"
                    startIcon={<ArrowForward />}
                >
                    Register
                </Button>
            </Box>

            <Backdrop open={fetcher.state !== "idle"} sx={{color: "#fff", zIndex: theme => theme.zIndex.drawer + 1}}>
                <CircularProgress size={64} color="inherit" />
            </Backdrop>

            <Dialog open={successDialogVisible}>
                <DialogTitle>Registration successful</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your account has been created.
                        Before you can sign in into the system, an administrator has to confirm your account.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={loginClickHandler}
                    >
                        Open login page
                    </Button>
                </DialogActions>
            </Dialog>

        </fetcher.Form>

    </AuthBoxLayout>
}

export default RegisterPage;