import React from "react";
import {useFetcher, useLoaderData, useNavigate} from "react-router-dom";
import FullPageLayout from "../../components/layouts/FullPageLayout";
import User from "../../models/User";
import {
    Button, Checkbox,
    Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip,
} from "@mui/material";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import UserAddDialog from "../../components/utils/UserAddDialog";

export const loader = appContext => async ({ params }) => {
    const currentUser = await appContext.user.require();
    let allUsers = (await appContext.axios.get("users")).data;

    // Check api response for correct format
    if (!Array.isArray(allUsers)) {
        throw new Error("Received invalid response from server.");
    }

    // Convert api response to client user model and sort by username
    allUsers = allUsers.map(User.fromApiData).sort((a, b) => (a.userName.toLowerCase() < b.userName.toLowerCase() ? -1 : 1));

    return {
        currentUser: currentUser,
        allUsers: allUsers
    };
}

export const action = appContext => async ({ request, params }) => {
    let formData = await request.formData();

    let sshKeys = [];
    formData.getAll("publicKey[]").forEach(k => {
        if (!(k in sshKeys)) {
            sshKeys = [...sshKeys, k]
        }
    });

    const data = {
        username: formData.get("userName"),
        display_name: formData.get("displayName"),
        password: formData.get("password"),
        password2: formData.get("passwordRepeat"),
        role: formData.get("role"),
        ssh_keys: sshKeys.map(k => ({
            publicKey: k
        })),
    };

    try {
        await appContext.axios.post(`/users`, data);
        appContext.enqueueSnackbar("Created new user.");
        return [];
    } catch (e) {
        let messages = [];
        if (typeof e.response?.data !== "object") {
            return ["The server sent an unexpected response."];
        }
        Object.keys(e.response.data).forEach(m => {
            messages = [...messages, ...e.response.data[m]]
        });
        return messages;
    }
}

function UsersItem({ user, canEdit, canDelete, isSelf }) {
    const navigate = useNavigate();
    const fetcher = useFetcher();

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [checkboxChecked, setCheckboxChecked] = React.useState(false);

    return (
        <>
            <TableRow hover>
                <TableCell>{user.displayName}</TableCell>
                <TableCell>{user.userName}</TableCell>
                <TableCell>
                    <Stack direction="row" spacing={1}>
                        {user.getRoleChip()}
                        {isSelf && <Chip label="You" color="info" variant="outlined" size="small" />}
                    </Stack>
                </TableCell>
                <TableCell align="right" sx={{py: 0}}>
                    <Tooltip title={canEdit ? "Edit" : "View"} placement="left">
                        <IconButton onClick={() => navigate(`/users/${user.id}`)}>
                            {(canEdit) ? <Edit /> : <Visibility />}
                        </IconButton>
                    </Tooltip>
                    {(canDelete) &&
                        <Tooltip title={isSelf ? "You cannot delete your own account." : "Delete"} placement="right">
                        <span>
                            <IconButton onClick={() => setDialogOpen(true)} disabled={isSelf}>
                                <Delete />
                            </IconButton>
                        </span>
                        </Tooltip>
                    }
                </TableCell>
            </TableRow>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Delete user</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the user <code>{user.displayName}</code>?
                        You can also disable the user to prevent it from accessing the LUM and the lab machines.
                    </DialogContentText>
                    <FormControlLabel control={
                        <Checkbox
                            onChange={e => setCheckboxChecked(e.target.checked)}
                            checked={checkboxChecked}
                        />
                    } label="I am aware that the user cannot be restored after deletion." />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
                    <fetcher.Form action={`/users/${user.id}/disable`} method="put" onSubmit={() => setDialogOpen(false)}>
                        <Button type="submit">Disable User</Button>
                    </fetcher.Form>
                    <fetcher.Form action={`/users/${user.id}/delete`} method="delete" onSubmit={() => setDialogOpen(false)}>
                        <Button type="submit" disabled={!checkboxChecked} color="error">Delete user</Button>
                    </fetcher.Form>
                </DialogActions>
            </Dialog>
        </>
    );
}

function UsersIndexPage(props) {
    const {currentUser, allUsers} = useLoaderData();
    const navigate = useNavigate();

    return <FullPageLayout
        title="Registered Users"
        description="The following users are currently registered."
        user={currentUser}
        onBack={() => navigate("/")}
    >
        <TableContainer component={Paper} variant="outlined">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Display&nbsp;name</TableCell>
                        <TableCell>Username</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell align="right" sx={{py: 0}}>
                            {currentUser.isAdmin() && <UserAddDialog />}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allUsers.map((user) => (
                        <UsersItem user={user}
                                   key={`user-row-${user.id}`}
                                   canEdit={currentUser.isAdmin() || user.id === currentUser.id}
                                   canDelete={currentUser.isAdmin()}
                                   isSelf={user.id === currentUser.id}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </FullPageLayout>;
}

export default UsersIndexPage;