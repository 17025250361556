import {useLoaderData, useNavigate} from "react-router-dom";
import FullPageLayout from "../../components/layouts/FullPageLayout";
import React from "react";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

export const loader = appContext => async ({ params }) => {
    const currentUser = await appContext.user.require();

    return {
        currentUser: currentUser
    };
}


function DocsIndexPage(props) {
    const {currentUser} = useLoaderData();
    const navigate = useNavigate();

    return <FullPageLayout
        title="Documentation"
        description="Here you can find the specification of the LUM API."
        user={currentUser}
        onBack={() => navigate("/")}
    >
        <SwaggerUI

            url="/openapi3_0.json"
        />
    </FullPageLayout>;
}

export default DocsIndexPage;