import React from 'react';
import {Navigate, useNavigate, useRouteError} from "react-router-dom";
import ApplicationContext from "../contexts/ApplicationContext";

function ErrorPage(props) {

    const error = useRouteError();
    const navigate = useNavigate();
    const appContext = React.useContext(ApplicationContext);

    if (error.name === "AuthenticationRequiredError") {
        return <Navigate to="/auth/login" />
    }

    console.error(error);

    if (error.name === "AxiosError") {
        switch (error?.response?.status) {
            case 400:
                // Authentication required
                return <Navigate to="/auth/login" />

            case 401:
                // Unauthorized
                if (error?.response?.data?.detail === "Invalid token.") {
                    appContext.user.logout().finally(() => {
                        navigate("/auth/login")
                    });
                    return null;
                }
                return <Navigate to="/auth/login" />

            default:
                break;
        }
    }

    if (typeof error.name === "undefined") {
        return <Navigate to={"/"} />
    }

    return <>
        <h1>Client Error (<code>{error.name}</code>)</h1>
        <p>An error has occurred. See the error message below.</p>
        <pre>{error.message}</pre>
    </>
}

export default ErrorPage;