import {
    AppBar, Backdrop,
    Button,
    Card, CardActions,
    CardHeader,
    CardMedia,
    List,
    ListItem, ListItemIcon, ListItemText,
    Popper, Stack,
    Toolbar
} from "@mui/material";
import {ArrowDropDown, Face, Logout, Person} from "@mui/icons-material";
import PasswordChangeDialog from "./PasswordChangeDialog";
import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import ApplicationContext from "../../contexts/ApplicationContext";
import UserProfileEditDialog from "./UserProfileEditDialog";

function MainAppBar({user}) {
    const navigate = useNavigate();
    const appContext = useContext(ApplicationContext);

    const userMenuButtonRef = React.useRef();

    const [userMenu, setUserMenu] = React.useState({
        open: false,
    });

    return <>
        <AppBar>
            <Toolbar>
                <Button onClick={() => navigate("/")} color="inherit" sx={{mr: "auto"}}>
                    RootSec LUM
                </Button>

                {user !== null ? <>
                        <span ref={userMenuButtonRef}>
                            <Button color="inherit" onClick={(e) => setUserMenu({open: true})}
                                    startIcon={<Person/>} endIcon={<ArrowDropDown/>}>
                                {user.userName}
                            </Button>
                        </span>
                    <Backdrop
                        open={userMenu.open}
                        sx={{zIndex: theme => (theme.zIndex.drawer + 1)}}
                        onClick={() => setUserMenu({...userMenu, open: false})}
                    >

                    </Backdrop>
                    <Popper
                        sx={{zIndex: theme => (theme.zIndex.drawer + 2), width: "400px", maxWidth: "calc(100vw - 32px)"}}
                        open={userMenu.open}
                        anchorEl={userMenuButtonRef.current}
                        placement="bottom-end"
                    >
                            <Card>
                                <CardHeader
                                    avatar={<Person/>}
                                    title="Current Session"
                                    action={
                                        <Button startIcon={<Logout />} color="error" onClick={() => appContext.user.logout().finally(() => {
                                            navigate("/auth/login");
                                        })}>
                                            Sign out
                                        </Button>
                                    }
                                />
                                <CardMedia>
                                    <List dense>
                                        <ListItem>
                                            <ListItemIcon>
                                                <Face />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Stack direction="row" spacing={1}>
                                                    <span>{user.displayName}</span>
                                                    {user.getRoleChip()}
                                                </Stack>}
                                                secondary={user.userName}
                                            />
                                        </ListItem>
                                    </List>
                                </CardMedia>
                                <CardActions>
                                    <PasswordChangeDialog user={user} requireOldPassword={!user.isAdmin()} />
                                    <UserProfileEditDialog user={user} canChangeDisplayName={true} canChangeRole={false} />
                                </CardActions>
                            </Card>
                    </Popper>
                </> : <>
                    <Button color="inherit" onClick={() => navigate("/auth/login")}>
                        Login
                    </Button>

                    <Button color="inherit" onClick={() => navigate("/auth/register")}>
                        Register
                    </Button>
                </>}
            </Toolbar>
        </AppBar>
        <Toolbar/>
    </>
}

export default MainAppBar;